import { styled } from '@mui/material/styles';
import excludeProps from '@/lib/theme/excludeProps';
import { color } from '@/lib/theme/tokens';
import Fade from '@/components/Fade';
import useContextStore from '../useContextStore';

const Children = styled('div', {
  shouldForwardProp: excludeProps('isOpen', 'isBarOpen'),
})(({ isOpen, isBarOpen }) => ({
  background: color.core.white,
  boxShadow: `-1px 0 0 ${color.primary.grey[200]}`,
  height: '100%',
  pointerEvents: 'all',
  position: 'absolute',
  top: 0,
  right: isBarOpen ? 352 : 0,
  transition: 'width 0.2s',
  width: isOpen ? 352 : 0,
  zIndex: 1,
}));

const Container = styled('div')({
  pointerEvents: 'none',
  position: 'absolute',
  height: 'calc(100vh - 45px)',
  width: '100%',
  zIndex: 1,
});

const Backdrop = styled('div')({
  backgroundColor: '#00000066',
  pointerEvents: 'all',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
});

const Overlay = ({ children, isOpen, isBarOpen }) => {
  const { resetOverlay } = useContextStore();
  const handleReset = () => {
    resetOverlay();
  };
  return (
    <Container>
      <Fade in={isOpen}>
        <Backdrop isOpen={isOpen} onClick={handleReset} />
      </Fade>
      <Children isOpen={isOpen} isBarOpen={isBarOpen}>
        {children}
      </Children>
    </Container>
  );
};

export default Overlay;
